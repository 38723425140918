/* * {
  outline: 1px red solid;
} */

body {
  @apply text-white;
  font-family: 'Michroma', sans-serif;
}

#video-placeholder-iframe {
  overflow: hidden;
}
#video-placeholder-iframe iframe {
  width: 100%;
  height: 70vh;
  border-radius: 1vw;
}

.link {
  @apply border-b border-white border-opacity-0 uppercase p-[2vh] block hover:opacity-70;
}

.link:hover {
  @apply border-opacity-100;
}

.slick-dots li button:before {
  color: white !important;
  @apply text-[5vw] md:text-[1.2vw];
}

.slick-list {
  z-index: 123123123;
}

.heading {
  @apply uppercase font-bold font-primary tracking-widest;
}

.heading--2 {
  @apply text-[17vw] leading-[100%] md:text-[15vw]  text-center text-primary text-opacity-70 -z-10;
}

.heading--4 {
  @apply text-[7vw] md:text-[2vw];
}

.heading--5 {
  @apply text-[3vw] md:text-[1.5vw];
}

.btn {
  @apply rounded-[.25vw] rounded-tl-[1vw] rounded-br-[1vw] pt-[2.5vw] pb-[2vw] text-[2vw] px-[2vw] md:py-[2vh] md:px-[2vh] uppercase md:text-[.8vw] leading-0 border hover:bg-transparent;
}

.btn--light {
  @apply bg-white text-black hover:border-white hover:text-white;
}

.btn--dark {
  @apply bg-black text-white hover:border-black hover:text-black;
}

.rounded-primary {
  @apply rounded-tl-[10vw] rounded-br-[10vw] rounded-[3vw] md:rounded-tl-[5vw] md:rounded-br-[5vw] md:rounded-[1vw];
}

.rounded--small {
  @apply rounded-tl-[2vw] rounded-br-[2vw] rounded-[.5vw];
}

.rounded--left {
  @apply rounded-tl-[20vw] rounded-bl-[20vw];
}

#music .slick-dots {
  top: 45%;
  left: -10%;
  display: flex !important;
  flex-direction: column;
}

.footer--link {
  @apply uppercase text-[2vw] md:text-[.8vw] md:leading-[40%];
}

.text.normal--size {
  @apply leading-[100%] text-[2.5vw] md:text-[1vw];
}

.text.big--size {
  @apply text-[4vw] md:text-[1.2vw];
}

.text-shadow {
  text-shadow: 2px 2px 4px black;
}
