/* Modules */
@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon/icomoon.eot?k9rpgi');
  src:
    url('../assets/fonts/icomoon/icomoon.eot?k9rpgi#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icomoon/icomoon.ttf?k9rpgi') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.woff?k9rpgi') format('woff'),
    url('../assets/fonts/icomoon/icomoon.svg?k9rpgi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@import '../assets/fonts/stylesheet.css';
@import '../assets/fonts/autumn-in-september/stylesheet.css';
/* 
* {
  outline: red 1px solid;
} */

body {
  font-size: 1vw;
}

#videos iframe {
  width: 100%;
  @apply h-[35vh] md:h-[55vh] rounded-tl-[10vw] rounded-br-[10vw] rounded-[3vw] md:rounded-tl-[5vw] md:rounded-br-[5vw] md:rounded-[1vw];
}

.bit-widget .bit-top-track-button,
.bit-nav-bar-container,
.bit-rsvp-container {
  display: none !important;
}
