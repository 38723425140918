/* purgecss start ignore */
.slider:hover .slider__nav {
  opacity: 1;
}
/* .slider__nav {
  display: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 48px;
  color: blue;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
} */

@media (min-width: 960px) {
  .slider__nav {
    display: block !important;
  }
}
/* .slider__nav--prev {
  right: calc(100% + 12px);
}
.slider__nav--next {
  left: calc(100% + 12px);
}
.slider__nav--small {
  font-size: 32px;
}
.slider__nav--small.slider__nav--prev {
  right: calc(100% + 4px);
}
.slider__nav--small.slider__nav--next {
  left: calc(100% + 4px);
} */
/* purgecss end ignore */
