@font-face {
    font-family: 'Autumn in September';
    src: url('AutumninSeptember.eot');
    src: url('AutumninSeptember.eot?#iefix') format('embedded-opentype'),
        url('AutumninSeptember.woff2') format('woff2'),
        url('AutumninSeptember.woff') format('woff'),
        url('AutumninSeptember.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

