/* purgecss start ignore */
.carousel-v2 .slick-track {
  @apply flex justify-center gap-5  lg:gap-10;
}

.carousel-v2 .slick-track:before,
.carousel-v2 .slick-track:after {
  @apply hidden;
}

/* @media (max-width: 1024px) {
  .carousel-v2 .slick-slide,
  .carousel-v2 .slick-slide.slick-active {
    margin: 0 10px;
  }
} */

@media (min-width: 1024px) {
  /* .carousel-v2 .slick-slide {
    transition: all 500ms ease;
  }

  .carousel-v2 .slick-slide.slick-active:not(:last-child) {
    @apply mr-10;
  } */
}
/* purgecss end ignore */
