/* purgecss start ignore */
.carousel-v3 .slick-track {
  @apply flex justify-center gap-5 lg:gap-10;
}

.carousel-v3 .slick-track:before,
.carousel-v3 .slick-track:after {
  @apply hidden;
}

@media (min-width: 1024px) {
  /* .carousel-v3 .slick-slide {
    transition: all 500ms ease;
  }

  .carousel-v3 .slick-slide.slick-active:not(:last-child) {
    @apply mr-10;
  } */
}
/* purgecss end ignore */
