/* purgecss end ignore */
/* Modules */

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider:hover .slick-prev:before,
.slick-slider:hover .slick-next:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'icomoon' !important;
  font-size: 48px;
  color: #fff;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.slick-prev {
  left: -4% !important;
}

.slick-prev:before {
  content: '\e902' !important;
}

.slick-next:before {
  content: '\e901' !important;
}
/* purgecss start ignore */
