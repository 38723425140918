.music-item {
  overflow: hidden;
  cursor: pointer;
}

.music-item img {
  transition: all 500ms ease;
}

.music-item:hover img {
  transform: scale(1.2);
}
